//@flow

import React from 'react';

import SEO from '../components/seo';
import HomeScreen from '../screens/Home';

type Props = {};

class IndexPage extends React.PureComponent<Props> {
  render = () => {
    return (
      <>
        <SEO title="Home" />
        <HomeScreen />
      </>
    );
  };
}

export default IndexPage;
