//@flow

import React from 'react';
import { injectIntl , FormattedMessage, FormattedHTMLMessage, Link } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Loadable from '@loadable/component';

// import { navigate } from 'gatsby-plugin-intl';

import auth from '../services/auth';

import Eye from '../icons/Eye.svg';
import Tooth from '../icons/Tooth.svg';
import Hand from '../icons/Hand.svg';
import Spine from '../icons/Spine.svg';
import Play from '../icons/Play.svg';
import Quizz from '../icons/Quizz.svg';
import Lotus from '../icons/Lotus.svg';
import StrechGuy from '../icons/StrechGuy.svg';

import { NonStretchedImage } from '../utils';

import {
  Wrapper,
  CTAWrapper,
  Block,
  AuthorWrapper,
  Title,
} from './Home.style';

const LoadablePWAPrompt = Loadable(() => import('react-ios-pwa-prompt'));

// type Props = {
//   intl: any,
// };

const Home = () => {
  const { avatar, intro, logo } = useStaticQuery(
    graphql`
      query HomeImages {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 220, toFormat: PNG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              presentationWidth
            }
          }
        }
        intro: file(relativePath: { eq: "home/intro-chat.png" }) {
          childImageSharp {
            fluid(maxWidth: 600, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        avatar: file(relativePath: { eq: "home/paccaud.jpg" }) {
          childImageSharp {
            fixed(width: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `);
  const isLoggedIn = auth.isLoggedIn();

  return (
    <Wrapper>
      <Img
        fluid={intro.childImageSharp.fluid}
        className="intro"
        style={{
          width: '100%'
        }}
      />
      <Title>
        <FormattedHTMLMessage id="home.title.heading" />
        <FormattedHTMLMessage id="home.title.description" tagName="p" />
        <div className="icons">
          <Eye />
          <Tooth />
          <Hand />
          <Spine />
        </div>
      </Title>
      <Block>
        <h3 className="title">
          <FormattedHTMLMessage id="home.title1" />
        </h3>
        <CTAWrapper>
          {isLoggedIn ? (
            <Link
              to="/app/"
              className="button outline"
            >
              <FormattedMessage
                id="program.button"
              />
            </Link>
          ) : (
            <>
              <Link
                to="/register"
                className="button"
              >
                <FormattedMessage
                  id="home.createAccount"
                />
              </Link>
              <Link
                to="/login"
                className="button outline"
              >
                <FormattedMessage
                  id="home.login"
                />
              </Link>
            </>
          )}
          
        </CTAWrapper>
      </Block>
      <Block>
        <div className="logo">
          <NonStretchedImage
            fluid={logo.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
          />
          <h3
            className="title"
          >
            <FormattedHTMLMessage id="home.title5" />
          </h3>
        </div>
        <div className="icons">
          <Lotus />
        </div>
        <h3
          className="title"
        >
          <FormattedHTMLMessage id="home.title4" />
        </h3>
        <div className="spacer" />
        <div className="icons">
          <StrechGuy
            height={65}
          />
        </div>
        <h3
          className="title"
        >
          <FormattedHTMLMessage id="home.titleTuto" />
        </h3>
        <div className="spacer" />
        <div className="icons">
          <Play
            height={33}
          />
        </div>
        <h3
          className="title"
        >
          <FormattedHTMLMessage id="home.title2" />
        </h3>
        <div className="spacer" />
        <div className="icons">
          <Quizz />
        </div>
        <h3
          className="title"
        >
          <FormattedHTMLMessage id="home.title3" />
        </h3>
      </Block>
      

      <CTAWrapper>
        {isLoggedIn ? (
          <Link
            to="/app/"
            className="button outline"
          >
            <FormattedMessage
              id="program.button"
            />
          </Link>
        ) : (
          <>
            <Link
              to="/register"
              className="button"
            >
              <FormattedMessage
                id="home.createAccount"
              />
            </Link>
            <Link
              to="/login"
              className="button outline"
            >
              <FormattedMessage
                id="home.login"
              />
            </Link>
          </>
        )}
        
      </CTAWrapper>
      <AuthorWrapper>
        <h3><FormattedMessage id="home.author.title" /></h3>
        <div className="header">
          <Img className="rounded" fixed={avatar.childImageSharp.fixed} />
          <h4>Julien Paccaud</h4>
        </div>
        <FormattedHTMLMessage id="home.author.description" />
      </AuthorWrapper>
      <LoadablePWAPrompt
        timesToShow={1}
        copyTitle="Ajouter à l'écran d'accueil"
        copyBody="My Body Ecology peut être utilisé comme une application. Installez la sur votre écran d'accueil pour une meilleure expérience"
        copyShareButtonLabel={'1) Appuyez sur le bouton "Partager"'}
        copyAddHomeButtonLabel={'2) Sélectionnez "Sur l\'écran d\'accueil"'}
        permanentlyHideOnDismiss={false}
        copyClosePrompt="Annuler"
      />
    </Wrapper>
  );
};

export default injectIntl(Home);
