import styled from 'styled-components';


export const Wrapper = styled.div`
  svg {
    path {
      fill: #86A5FF;
    }
  }

  .intro {
    margin: -20px -35px 0 -35px;
    width: auto!important;
  }
`;

export const Title = styled.h2`
  position: relative;
  text-align: center;
  font-size: 21px;
  max-width: 270px;
  margin: -40px auto 30px auto;
  z-index: 2;

  b {
    font-size: 25px;
  }

  p {
    font-size: 20px;
    color: #86A5FF;
  }

  .icons {
    display: block;
    margin-top: 30px;

    svg {
      margin: 0 10px;
      vertical-align: middle;
    }
  }
`;

export const Block = styled.section`
  border-top: 1px solid #444444;
  text-align: center;
  margin: 0px 0;
  padding: 30px 0;
  
  .icons {
    display: block;

    svg {
      margin: 0 10px;
      vertical-align: middle;
    }
  }

  .title {
    font-size: 18px;
    font-family: 'Montserrat';
    margin: 15px auto 0 auto;
    max-width: 270px;
    line-height: 1.3em;
  }

  .spacer {
    display: block;
    width: 100%;
    margin: 35px 0;
  }

  .logo {
    margin-bottom: 30px;

    h3 {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .title {
      color: #86A5FF;
    }
  }
`;

export const CTAWrapper = styled.section`
`;

export const AuthorWrapper = styled.section`
  margin: 50px 0;
  border-top: 1px solid #444444;
  padding: 25px 0;

  h3 {
    text-transform: uppercase;
    font-size: 10px;
  }

  h4 {
    font-family: Montserrat;
    font-size: 18px;
    margin: 10px 0;
  }

  .header {
    text-align: center;
    margin: 20px 0;
  }

  .rounded {
    border-radius: 50%;
    overflow: hidden;
  }

  p {
    font-size: 15px;
    font-family: Montserrat;
    line-height: 1.2em;

    b {
      display: block;
    }
  }
`;